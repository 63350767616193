<template lang="pug">
  .Card.ajax_block_product(aria-hidden='false', :data-id-product='product.id', :data-id-product-attribute='product.id_product_attribute')
    .Card-container(itemscope='', itemtype='https://schema.org/Product')

      .CardBody
        .CardBody-percent(v-if="product.price.reduction")
            p.CardBody-percent-reduction {{ discount }} OFF
        .CardBody-delivery
          figure(v-if="product.marks", v-for="mark in product.marks", :key="mark.id")
            img(loading="lazy" :src='mark.image', :title='mark.name', :alt='mark.name')

        .CardBody-image
          figure
            img.replace-2x.img-responsive(loading="lazy" :src='product.cover', :alt='product.name', :title='product.name', itemprop='image')

        .CardBody-payments
          .CardBody-old-price(v-if="product.price.reduction") 
            span(v-if="product.onlyPoints") {{product.points[0].old | formatPoints}} Puntos
            span(v-else) $ {{ product.price.list | numberFormat }}
          .CardBody-old-price(v-else)
          h3.CardBody-price(v-if="!product.onlyPoints") <span>$</span>{{ product.price.final | numberFormat }} &nbsp;
          .CardBody-base-price-with-reductions(v-if="product.price.use_base_price_with_reductions") <span>Precio sin impuestos $</span>{{product.price.base_price_with_reductions | numberFormat}}
          .CardBody-supplier
            figure
              img.replace-2x.img-responsive(loading="lazy" :src='product.supplier.image', :title='product.supplier.name', :alt='product.supplier.name')
          .CardBody-points(v-if="product.activePoints" :class="{ 'CardBody-points-marginTop': (product.activePoints && product.onlyPoints) }")
            | {{ (product.activePoints && product.onlyPoints) ? '' : 'Ó' }}
            span {{product.points[0].amount | formatPoints}}
            | puntos
          .CardBody-shadow
          .CardBody-white
          .CardBody-name(itemprop='name') {{ product.name.toLowerCase()}}
          //- p.CardBody-description Una descripcion
      .CardFooter(v-if="product.price.quota_without_interest && !(product.activePoints && product.onlyPoints)")
        .CardFooter-cart
          figure
            img(loading="lazy" src='/themes/supervielle/img/add_to_cart.png', title='add_to_cart', alt='agregar al carrito')
        .CardFooter-quota
          figure
            img.replace-2x.img-responsive(loading="lazy" src="/themes/supervielle/img/tarjeta_SPV.png", itemprop='image')
          p.CardFooter-tax
            strong(v-if="product.price.quota_without_interest == 1")
                | 1 cuota de ${{ costQuota | numberFormat }} &nbsp;
            strong(v-else)
                | {{ product.price.quota_without_interest }} cuotas de ${{ costQuota | numberFormat }} &nbsp;
            <span>sin interés</span> con Tarjetas Supervielle

      a.CardBody-link(:href='product.link', :title='product.name', itemprop='url')
</template>

<script>
import { numberFormat } from '@/filters/number-format'

export default {
  name: 'CardProduct',
  props: {
    product: { type: [Object, String], required: true }
  },
  methods: {
    parseProduct () {
      if (typeof this.product === 'string') {
        this.product = JSON.parse(this.product)
      }
    }
  },
  computed: {
    costQuota () {
      let price = parseFloat(this.product.price.final)
      let quotas = parseInt(this.product.price.quota_without_interest)

      let result = price / quotas

      return result
    },
    discount () {
      let reduction = this.product.price.reduction
      let result = parseInt(Math.round(reduction * 100))
      return `${result}%`
    }
  },
  filters: {
    numberFormat,
    formatPoints(value) {
      if (typeof value === 'number') {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return value;
    },
  },
  beforeMount () {
    this.parseProduct()
  },
  beforeUpdate () {
    this.parseProduct()
  }
}
</script>

<style lang="scss" scoped>
  $primary: #ed2224;
  $secondary: #444445;
  $color-grey: #6a6b6d;
  $color-white: #fff;
  $background-gradient: linear-gradient(
    45deg,
    rgba(237, 34, 36, 1) 0,
    rgba(130, 22, 67, 1) 100%
  );
  $background-gradient-invested: linear-gradient(
    45deg,
    rgba(130, 22, 67, 1) 0,
    rgba(237, 34, 36, 1) 100%
  );
  $width-card: 80vw;
  $root-font-size: 1rem;
  $font-family: Whitney, sans-serif;

  #category .CardBody-image {
    @media (min-width: 1024px) {
      height: auto;
      figure {
        max-width: 100%;
        img {
          width: 75%;
          margin: auto;
          position: relative;
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem;
        }
      }
    }
  }
  .Card {
    font-family: $font-family;
    font-size: $root-font-size * 1.5;
    padding: 0.5em;
    width: 100%;
    height: auto;
    position: relative;

    &-container {
      max-width: $width-card;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &Body {
      padding-top: 1rem;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
      position: relative;
      border-radius: 1rem 1rem 0 0;
      background: #fff;
      height: 480px;

      &-delivery {
        padding: 0rem;
        top: 8px;
        right: 24px;
        position: absolute;
        z-index: 1;
        display: flex;
        flex-direction: row-reverse;
        width: 100%;

        @media (max-width: 767px) {
          top: 31px;
        }

        figure {
          // background-color: #fff;
          // box-shadow: 2px 1px 8px #cfcecea8;
          margin-left: 0.8rem;
          // padding: 0.4rem;
          max-width: 44px;
          border-radius: 0.3rem;

          img {
            max-width: 100%;
          }
        }
      }

      &-image {
        height: 175px;
        margin-top: 35px;
        figure {
          width: 100%;
          img {
            max-width: 75%;
            margin: auto;
            position: relative;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
            @media (min-width: 768px) {
              img {
                max-width: 100%;
              }
            }
          }
        }
        @media (min-width: 360px) {
          height: 215px;
        }
    }

      &-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
      }

      &-name {
        color: #4B4B4C;
        font-family: Whitney;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        overflow: hidden;
        text-transform: capitalize !important;
        margin-bottom: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &-block-not-points {
        height: 30px;
      }

      &-shadow {
        opacity: .4;
        background: #d6d4d4;
        filter: blur(3.5px);
        width: 100%;
        height: 15px;
      }

      &-white {
        background: #fff;
        width: 107%;
        height: 15px;
        position: relative;
        top: -19px;
        transform: translateX(-15px);
      }

      &-supplier {
        position: absolute;
        top: -5px;
        right: 10px;
        margin: 0;

        figure {
          height: auto;

          img {
            height: 3.3rem;
            object-fit: cover;
          }
        }
      }

      &-old-price {
        color: #777;
        font-family: Whitney;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        padding: 0;
        height: 1.2em;
        margin-bottom: 0.3em;
        text-decoration: line-through;
      }

      &-payments {
        margin: .5rem 10px .5rem 15px;
        text-align: left;
        height: 14rem;
        position: relative;
      }

      &-percent {
        text-align: center;
        display: inline-block;
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 8px;
        background: $background-gradient-invested;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        width: 34%;

        @media (max-width: 767px) {
          top: 31px;
        }

        &-reduction {
          margin: .8rem;
          font-size: 1em;
          font-weight: 500;
          line-height: 1.5rem;
          color: #fff;
        }
      }

      &-price {
        color: #ED2325;
        font-family: Whitney;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.25px;
        margin: 0;
        display: inline-block;

        span {
          font-size: 2rem;
        }
      }

      &-base-price-with-reductions {
        font-family: Whitney;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #858585;
        margin: 0;
        height: 20px;
      }

      &-points {
        padding: 7px 14px 7px 10px;
        color: #696969;
        font-family: Whitney;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
        margin: 10px 0 6px 0;
        border-radius: 20px;
        position: relative;

        &-marginTop {
          margin-top: 30px;
        }

        span {
          color: #ED2224;
          font-size: 18px;
          font-weight: 700;
          letter-spacing: 0.18px;
          margin: 0 5px;
        }

        .img {
          position: absolute;
          right: -8px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 50px;
          border: 1px solid #E6A523;
          background: linear-gradient(90deg, #82173E 0%, #C1252D 100%);
          padding: 4.962px 5px;
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 52px;
          z-index: 1;

          img {
            width: 85%;
            height: auto;
          }
        }
      }
    }
    &Footer {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
      background: $background-gradient;
      color: white;
      text-align: left;
      min-height: 3em;
      border-radius: 10px;
      position: relative;
      top: -4px;

      &-percentage {
        text-align: left;
        display: inline-block;
        width: auto;
        padding: 0% 1%;

        span {
          font-size: 1.3em;
          font-weight: bold;
          line-height: 0.9em;
        }

        p {
          font-size: 0.5em;
          margin: 0;
          line-height: 1em;
        }

        .percent {
          font-size: 1em;
          font-weight: bold;
          display: inline-block;
          width: auto;
          margin: auto;
          line-height: 0.6em;
        }
      }

      &-quota {
        height: auto;
        padding: 1rem 0.5rem 0.6rem 0.5rem;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        gap: 15px;

        figure {
          max-width: 77px;
          padding: 0.5rem 0 0.5rem 0rem;

          img {
            width: 100%;
            filter: drop-shadow(0px -2px 3px rgba(0, 0, 0, 0.3));
          }
        }
      }

      &-tax {
        font-size: 1.2rem;
        margin-bottom: 0px;
        font-weight: 500;
        line-height: 1.3rem;
        flex: 1;

        strong {
          text-transform: uppercase;
          font-size: 1.2rem;
          line-height: 1.3rem;
        }
        span {
          font-size: 1.3rem;
        }
      }

      &-cart {
        position: absolute;
        top: -1.8rem;
        right: 0rem;

        figure {
          background-color: #fff;
          box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.2);
          margin-right: 1rem;
          padding: 0.4rem;
          max-width: 35px;
          border-radius: 0.3rem;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  @media all and (min-width: 360px) {
    $root-font-size: 16px;

    .Card {
      font-size: $root-font-size;

      &Body {
        &-delivery figure {
          max-width: 39px;
        }

        &-percent {
          width: 32%;

          &-reduction {
            margin: 1rem;
          }
        }
      }

      &Footer {
        &-quota {
          padding: 2rem 1.3rem 1rem 1.3rem;

          figure {
            max-width: 77px;
            padding: 0.5rem 0 0.5rem 0rem;

            img {
              width: 100%;
            }
          }
        }

        &-tax {
          font-size: 1.3rem;
          line-height: 1.5rem;

          strong {
            font-size: 1.3rem;
            line-height: 1.5rem;
          }

          span {
            font-size: 1.4rem;
          }
        }

        &-cart {
          figure {
            margin-right: 1.2rem;
          }
        }
      }
    }
  }

  @media all and (min-width: 410px) {
    .Card {
      font-size: $root-font-size * 1.8;

      &Body {
        &-delivery figure {
          max-width: 39px;
        }

        &-payments {
          height: 15rem;
        }
      }

      &Footer {
        &-cart {
          figure {
            margin-right: 1.3rem;
            max-width: 38px;
          }
        }

        &-tax {
          font-size: 1.5rem;

          strong {
            font-size: 1.5rem;
          }

          span {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  @media all and (min-width: 768px) {
    .Card {
     &Body {
         &-percent {
          width: 42%;
        }
     }
      &Footer {
        &-quota {
          figure {
            max-width: 77px;

            img {
              width: 100%;
            }
          }
        }
          &-tax {
          font-size: 1.2rem;

          strong {
            font-size: 1.2rem;
          }

          span {
            font-size: 1.3rem;
          }
         }
      }
    }
  }

  @media all and (min-width: 1025px) {
    .Card {
      &-container {
        &:hover {
          cursor: pointer;

          .CardBody {
            border-radius: 1rem;
          }

          .CardFooter {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.4s linear;
            background: $background-gradient;
          }
        }
      }

      &Body {
        border-radius: 1rem;

        &-name {
          font-size: 1.6rem;
        }

        &-old-price {
          font-size: 1.5rem;
        }

        &-payments {
          height: 15.1rem;
        }

        &-price {
          font-size: 1.2em;
          font-weight: bold;
        }
      }

      &Footer {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0s, opacity 0s linear;

        &-quota {
          figure {
            max-width: 77px;
            padding: 0.5rem 0 0.5rem 0rem;
          }
        }

        &-tax {
          font-size: 1.3rem;

          strong {
            font-size: 1.3rem;
          }

          span {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  @media all and (min-width: 1300px) {
    .Card {
      &Body {
        &-percent {
          width: 40%;
        }
      }
    }
  }

  @media all and (min-width: 1600px) {
    .Card {
      &Footer {
        &-quota {
          figure {
            max-width: 77px;
          }
        }
        &-tax {
          font-size: 1.3rem;

          strong {
            font-size: 1.4rem;
          }

          span {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  @media all and (min-width: 1900px) {
    .Card {
      &Body {
        &-percent {
          width: 35%;
        }

        &-price {
          font-size: 1.4em;
        }
      }

      &Footer {
        &-quota {
          figure {
            max-width: 77px;
          }
        }

        &-tax {
          font-size: 1.5rem;

          strong {
            font-size: 1.4rem;
          }

          span {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
</style>
